import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

class RawSearchResultComponent extends React.PureComponent {

    handleClick() {
        this.props.handleClick(this.props.institution)
    }
    render() {
        if (!this.props.institution) {
            return null;
        }

        const hasSubtitle = this.props.institution.untertitel && this.props.institution.untertitel.length > 1;

        return (<Link to={"/institution/" + this.props.institution.slug}><div className={this.props.className}>
            <h3>{this.props.institution.name}</h3>
            {hasSubtitle ?  <p className="subtitle">{this.props.institution.untertitel}</p> : null}
            <p className="place">
                {this.props.institution.ort}
            </p>
        </div></Link>)
    }
}

export const SearchResultComponent = styled(RawSearchResultComponent)`
padding-top: 15px;
padding-bottom: 15px;
border-bottom: 1px solid #ccc;
border-spacing: 10px 100px;
padding-left: 5px;
cursor: pointer;

h3 {
    margin-top: 0;
    margin-bottom: 0px;
}

.subtitle {
    margin-bottom: 0;
    margin-top: 0;
}

.place {
    margin-top: 10px;
    margin-bottom: 0;
    color: #00000087;
}

&:hover {
background: #f9f9f9;
border-bottom: 1px solid #E20319;
}
`;