import React from 'react'
import styled from 'styled-components'
import {SearchResultContainer} from "../containers/SearchResultContainer";

export class RawSearchResultsComponent extends React.PureComponent {

    render() {
        return (<div className={this.props.className}>
            {this.props.institutionIds.map(id => {
                return <SearchResultContainer key={id} institutionId={id} />
            })}
        </div>);
    }
}

export const SearchResultsComponent = styled(RawSearchResultsComponent)`
    margin-top: 30px;
`;