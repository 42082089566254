import $ from "jquery";

export class RequestQueue {
    constructor(dispatch) {
        this.dispatch = dispatch;
        this.number = 0;
        this.delay = 300; // minimum waiting
        this.waitingForResponse = false;
    }

    request(search, required) {
        if (!search.tags) {
            search.tags = [];
        }
        if (search.tags.length <= 0 && !Number.isInteger(search.categoryId) && search.input.length <= 0) {
            this.cancelRequest();
            this.clearQueue();
            this.dispatch({type: 'CLEAR_RESULTS'});
            return;
        }

        if (required) {
            this.cancelRequest();
            this.clearQueue();
            this._makeSearchRequest(search);
            return;
        }

        if (search.tags.length <= 0 && !Number.isInteger(search.categoryId) && search.input.length < 3) {
            return;
        }

        var timeDifference = 0;
        if (typeof this.requestDate != 'undefined') {
            var now = new Date();
            timeDifference = now.getTime() - this.requestDate.getTime();
        }

        if (typeof this.requestDate == 'undefined' || this.waitingForResponse == false) {
            this._makeSearchRequest(search);
        } else {
            if (timeDifference < this.delay) {
                // too many requests -> wait
                var delay = this.delay - timeDifference;
                this.clearQueue();
                this.timer = setTimeout(function() {
                    this.request(search, required);
                }.bind(this), delay);
            } else {
                if (this.waitingForResponse) {
                    this.nextRequest = function() {
                        this._makeSearchRequest(search);
                    }
                } else {
                    this._makeSearchRequest(search);
                }
            }
        }
    }

    cancelRequest() {
        delete this.nextRequest;
        this.waitingForResponse = false;
        if (typeof this.pendingRequest != 'undefined') {
            this.pendingRequest.abort();
            delete this.pendingRequest;
        }
    }


    clearQueue() {
        if (typeof this.timer != 'undefined') {
            clearTimeout(this.timer);
            delete this.timer;
        }
    }

    _makeSearchRequest(search) {
        console.log(search);
        this.waitingForResponse = true;
        this.requestDate = new Date();

        this.clearQueue();
        //this.$indicator.css('opacity', 1);

        this.dispatch({type: "WAITING_FOR_RESPONSE", waiting: true});


        var nulledSearch = {...search}
        if (nulledSearch.categoryId === null) {
            delete nulledSearch.categoryId
        }

        this.pendingRequest = window.request('onSearch', {
            data: {
                search: nulledSearch,
            },
            success: function(data) {
                this.successCallback(data);
            }.bind(this),

            error: function(e) {
                // also called on abort
                console.log(e);
                console.log(e.responseText);
            }.bind(this),
            complete: function() {
                this.waitingForResponse = false;
                this.dispatch({type: "WAITING_FOR_RESPONSE", waiting: false});
                if (typeof this.nextRequest != 'undefined') {
                    this.nextRequest();
                    delete this.nextRequest;
                }
            }.bind(this)
        });
    }


    successCallback(data) {
        console.log(data);
        if (!data) {
            return;
        }

        if (data.results) {
            this.dispatch({type: "CHANGE_RESULTS", results: data.results})
        }


        //$('.searchResults').html(html);
    }
}