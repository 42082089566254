import React from "react";
import styled from "styled-components";
import {InstitutionComponent} from "./InstitutionComponent";
import {PopupComponent} from "./PopupComponent";
import {withRouter, Redirect} from 'react-router-dom';

class RawInstitutionPopupComponent extends React.PureComponent {
    render() {
        const slug = this.props.match.params.slug;

        const i = window.institutionsSlugMap.get(slug);

        if (!i) {
            return <Redirect to={"/"} />;
        }

        /*
        var maps = null;
        var searchString = null

        const includesPostbox = (str) => {
            return str.toLowerCase().includes('postf')
        }

        if (i.strasse && !includesPostbox(i.strasse)) {
            searchString = i.strasse
            searchString += i.plz && !includesPostbox(i.plz)? ', ' + i.plz : ''
            searchString += i.ort && !includesPostbox(i.ort) ? ', ' + i.ort : ''
            searchString += ', Schweiz'
            searchString = encodeURI(searchString)
            var mapsUrl = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCZdjHmVVXjReuFUqC-BhSIFr3w43TKxSc&zoom=15&q=" + searchString
            maps = <iframe className="maps" width="100%" height="450" frameborder="0"
            src={mapsUrl} allowfullscreen>
            </iframe>
        }*/

        return (
            <PopupComponent>
                <InstitutionComponent institution={i} />
            </PopupComponent>)
    }
}

export const InstitutionPopupComponent = withRouter(styled(RawInstitutionPopupComponent)`
 
`)