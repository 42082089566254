import { connect } from 'react-redux'
import {TagSuggestionsComponent} from "../components/TagSuggestionsComponent";

const mapStateToProps = (state, ownProps) => {
    const isHintSuggestion = state.search.categoryId == null;
    return {
        tags: isHintSuggestion ? state.hintTagSuggestions : state.tagSuggestions,
        isHintSuggestion: isHintSuggestion
    }
}

export const TagSuggestionsContainer = connect(
    mapStateToProps
)(TagSuggestionsComponent);
