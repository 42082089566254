import { connect } from 'react-redux'
import {SearchComponent} from "../components/SearchComponent";
import {RequestQueue} from "../network/RequestQueue";

var requestQueue = null;

const mapStateToProps = (state, ownProps) => {
    return {
        search: state.search,
        inputFocus: state.inputFocus
    }
}

const mapDispatchToProps = dispatch => ({
    searchChanged: (search) => {
        if (!requestQueue) {
            requestQueue = new RequestQueue(dispatch);
        }

        requestQueue.request(search, false);
    }
})


export const SearchContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchComponent);