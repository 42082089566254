import React from 'react'
import ReactAutocomplete from 'react-autocomplete';
import styled from 'styled-components';
import {isMobile} from "react-device-detect";

export class RawSearchInputComponent extends React.PureComponent {
    constructor (props) {
        super(props)
        this.input = null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.inputCategoryId !== this.props.inputCategoryId || nextProps.inputTags !== this.props.inputTags) {
            if (this.input != null) {
                this.input.focus();
            }
        }
    }

    componentDidMount() {
        this.input.focus();

        if (isMobile) {
            window.addEventListener('scroll', this.handleMobileScroll.bind(this));
        }
    }

    handleMobileScroll() {
        if (!this._focused) {
            return;
        }

        if (this._focusedDate && Date.now() - this._focusedDate > 500) {
            this.input.blur();
        }
    }

    valueChanged(e) {
        this.props.inputChanged(e.target.value);
    }

    tagSelected(t) {
        this.props.tagSelected(t)
    }

    handleKeypress(e) {
        if (e.key === 'Backspace' && this.props.input.length === 0) {
            this.props.backspacePressed();
        }
        if (e.key === 'Enter') {
            setTimeout(() => {
                this.input.blur();
            }, 1);
        }
    }

    shouldItemRender(item, value) {
        return item.toLowerCase().startsWith(value.toLowerCase());
    }

    inputFocused() {
        if (this._focused) {
            return
        }
        this.props.inputFocused()

        this._focused = true

        this._focusedDate = Date.now();
    }

    inputBlurred() {
        if (!this._focused) {
            return
        }
        this.props.inputBlurred()

        this._focused = false
    }

    render() {

        return <ReactAutocomplete
            autoHighlight={true} // the client requested this
            ref={el => this.input = el}
            open={this._focused && this.props.input.length >= 1}
            items={this.props.tags}
            shouldItemRender={this.shouldItemRender.bind(this)}
            getItemValue={item => item}
            sortItems={(a, b) => {
                if (a.length > b.length) {
                    return 1;
                } else if (b.length > a.length) {
                    return -1;
                }
                return 0;
            }}
            renderItem={(item, highlighted) => {

                return (<div
                    className={`autocomplete-item ${highlighted ? 'highlighted' : ''}`}
                    key={item}
                >
                    {item}
                </div>);
            }
            }
            inputProps={
                {
                    id: "searchInput",
                    type: "text",
                    name: "search",
                    onKeyDown: this.handleKeypress.bind(this),
                    onFocus: this.inputFocused.bind(this),
                    onBlur: this.inputBlurred.bind(this),
                    placeholder: "Volltextsuche / Stichwort"
                    //onPaste: (e) => { this.setState({value: "paste"}); e.preventDefault(); }
                }
            }
            wrapperStyle={{flexWrap: "wrap", display: "flex", position: "relative", zIndex: 1}}
            wrapperProps={{className: this.props.className}}
            menuStyle={{position: "absolute", top: '47px', left: 0}}
            value={this.props.input}
            onChange={this.valueChanged.bind(this)}
            onSelect={this.tagSelected.bind(this)}
        />
    }
}


export const SearchInputComponent = styled(RawSearchInputComponent)`
    min-width: 100px;
    flex-grow: 1;

    input {
        height: 50px;
        width: 1px;
        flex-grow: 1;
        border: none;
        box-shadow: none;
        color: rgb(76, 76, 76);
        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    .autocomplete-item {
        cursor: pointer;
        padding: 5px;
        background: white;
        &.highlighted {
            background: #eee;
        }
    }

    .autocomplete-item, input {
        font-size: 18px;
    }

    @media screen and (min-width: 500px) {
        .autocomplete-item, input {
            font-size: 24px;
        }
    }
`;
