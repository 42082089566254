import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';

class RawEmergencyButtonComponent extends React.PureComponent {
    render() {
        return (<Link to="/notfall"><div href="#" className={this.props.className}>
            <img src="/react-files/emergency-icon.svg"/>
            <span>Notfall</span>
        </div></Link>);
    }
}

export const EmergencyButtonComponent = styled(RawEmergencyButtonComponent)`
        background: rgb(232, 130, 130);
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: 0 0 3px 0 rgb(196, 196, 196);

        img {
            max-width: 20px;
            max-height: 20px;
        }

        span {
            padding-left: 5px;
            color: white;
            font-size: 15px;
        }

        transition: transform 0.2s;
        &:hover {
            transform: scale(1.03);
            z-index: 3;
        }
`;
