import { connect } from 'react-redux'
import {CategoryButtonComponent} from "../components/CategoryButtonComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        category: ownProps.category,
        active: ownProps.category.id == state.search.categoryId
    }
}

const mapDispatchToProps = dispatch => ({
    buttonClicked: category => {
        dispatch({type: 'TOGGLE_CATEGORY', categoryId: category.id})

        const tags = window.jungundalt.tagSuggesions[category.id].split(',');
        dispatch({type: 'SET_TAG_SUGGESTIONS', tags: tags})
    },
    buttonEntered: category => {
        const tags = window.jungundalt.tagSuggesions[category.id].split(',');
        dispatch({type: 'HINT_TAG_SUGGESTIONS', tags: tags, categoryId: category.id})
    },
    buttonLeft: category => {
        dispatch({type: 'HINT_TAG_SUGGESTIONS', tags: [], categoryId: null})
    }
})

export const CategoryButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryButtonComponent);
