import { connect } from 'react-redux'
import {SearchInputComponent} from "../components/SearchInputComponent";
import {TagListComponent} from "../components/TagListComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        tags: state.search.tags,
        category: state.search.categoryId != null ? window.categories.find(c => c.id === state.search.categoryId) : null
    }
}



export const TagListContainer = connect(
    mapStateToProps
)(TagListComponent);