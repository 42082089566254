import { connect } from 'react-redux'
import {SearchComponent} from "../components/SearchComponent";
import {SearchResultComponent} from "../components/SearchResultComponent";

const mapStateToProps = (state, ownProps) => {
    const institution = window.institutionsMap.get(ownProps.institutionId);

    return {
        institution: institution
    }
}


const mapDispatchToProps = dispatch => ({
    
})

export const SearchResultContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResultComponent);