import React from "react";
import styled from "styled-components";

class RawInstitutionComponent extends React.PureComponent {
    render() {
        const i = this.props.institution;

        var maps = null;
        var searchString = null

        const includesPostbox = (str) => {
            return str.toLowerCase().includes('postf')
        }

        if (i.strasse && !includesPostbox(i.strasse)) {
            searchString = i.strasse
            searchString += i.plz && !includesPostbox(i.plz)? ', ' + i.plz : ''
            searchString += i.ort && !includesPostbox(i.ort) ? ', ' + i.ort : ''
            searchString += ', Schweiz'
            searchString = encodeURI(searchString)
            var mapsUrl = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCZdjHmVVXjReuFUqC-BhSIFr3w43TKxSc&zoom=15&q=" + searchString
            maps = <iframe className="maps" width="100%" height="450" frameborder="0"
                           src={mapsUrl} allowfullscreen>
            </iframe>
        }

        return (
                <div className={this.props.className}>
                    <div className="institution content">
                        <h1>{i.name}</h1>
                        {i.untertitel ? <p className="untertitel">{i.untertitel}</p> : null}
                        <p className="address">
                            {i.strasse && i.strasse}
                            {i.strasse && <br />}
                            {i.plz} {i.ort}
                        </p>

                        <p className="links">
                            {i.email ? <a href={'mailto:' + i.email}>Kontakt</a> : null}
                            {i.url ? <a href={i.url} target="_blank">Homepage</a> : null}
                        </p>

                        {i.tel &&
                        <p className="phone">
                            <a href={'tel:' + i.tel}>Telefon {i.tel}</a>
                        </p>
                        }


                        {i.kurzbeschreibung &&
                        <div className="description" dangerouslySetInnerHTML={{__html: i.kurzbeschreibung}}>
                        </div>
                        }
                    </div>
                    {maps && maps}
                </div>
        );
    }
}

export const InstitutionComponent = styled(RawInstitutionComponent)`
    .institution {
          
        @media screen and (min-width: 600px) {
            margin: 40px 40px 40px 40px;
        }
       
        .address,
        .links {
            margin: 20px 0;
        }
        .links a {
          display: block;
          margin: 10px 0;
          font-size: 24px;
        }
        .description {
          margin: 20px 0;
        }
        .phone span,
        .phone a {
          display: block;
        }
    }
    
    .maps {
        width: 100%;
        height: 450px;
        border: none;
    }
`