import React from 'react'
import styled from 'styled-components'
import {isMobile} from 'react-device-detect';

class RawCategoryButtonComponent extends React.PureComponent {

    buttonClicked() {
        this.props.buttonClicked(this.props.category);
    }

    mouseEnter() {
        if (isMobile) { return; }
        this.props.buttonEntered(this.props.category);
    }

    mouseLeave() {
        if (isMobile) { return; }
        this.props.buttonLeft(this.props.category);
    }

    render() {
        return (
            <div className={this.props.className} onClick={this.buttonClicked.bind(this)} onMouseEnter={this.mouseEnter.bind(this)}
                 onMouseLeave={this.mouseLeave.bind(this)}>
                <div className={"inner"}>
                    <div className={"bg"} />
                    <span>{this.props.category.name}</span>
                </div>
                {this.props.active ? <div className={"underline"}></div> : null}
            </div>);
    }
}


export const CategoryButtonComponent = styled(RawCategoryButtonComponent)`
    height: 110px;
    width: calc(33.3333333% - 10px);
    justify-content: flex-start;
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;

    cursor: pointer;

    .inner {
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        height: 82px;
        padding: 10px 10px 5px 10px;
         background: ${props => props.category.color};
         position: relative;

         .bg {
            flex-grow: 1;
            background: url("/themes/jungundalt/assets/categories/${props => props.category.id}.png?v=3");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-origin: content-box;
            padding: 5px;
         }
         span {
            color: white;
         }
         transition: transform 0.2s;
        &:hover {
            transform: scale(1.03);
            z-index: 3;
        }
    }

    .underline {
        border-bottom: 5px solid ${props => props.category.color};
        margin-top: 4px;
        border-radius: 2px;
    }

    @media screen and (min-width: 800px) {
        height: 130px;
        width: calc(16.666% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        .inner {
            height: 105px;
        }
    }
`;
