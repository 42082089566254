import { connect } from 'react-redux'
import {SearchResultsComponent} from "../components/SearchResultsComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        institutionIds: state.results
    }
}



export const SearchResultsContainer = connect(
    mapStateToProps
)(SearchResultsComponent);