import React from 'react'
import {SearchInputContainer} from "../containers/SearchInputContainer";
import {TagListContainer} from "../containers/TagListContainer";
import styled from 'styled-components';
import isEqual from "react-fast-compare";

export class RawSearchComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        const equal = isEqual(this.props.search, nextProps.search);

        if (!equal) {
            this.props.searchChanged(nextProps.search);
        }

        if (nextProps.inputFocus != this.props.inputFocus) {
            return true
        }

        return !equal;
    }

    render() {
        return (<div className={this.props.className}>
            <TagListContainer/>
            <SearchInputContainer/>
        </div>)
    }
}

export const SearchComponent = styled(RawSearchComponent)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    box-shadow: ${props => props.inputFocus ? "0 3px 8px 0 rgba(0,0,0,0.2),0 0 0 1px rgba(0,0,0,0.08)" : "0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08)"};

    transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);

    border-radius: 2px;

    margin-top: 0px;
    box-sizing: border-box;
    display: flex;

    padding: 5px 8px;
    @media screen and (min-width: 500px) {
        padding: 6px 10px;
    }
`;
