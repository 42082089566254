import React from 'react'
import styled from 'styled-components'
import {CategoryButtonContainer} from "../containers/CategoryButtonContainer";


class RawCategoryButtonListComponent extends React.PureComponent {

    render() {
        return (<div className={this.props.className}>
            {this.props.categories.map(c => {
                return <CategoryButtonContainer key={c.id} category={c} />
            })}
        </div>);
    }
}


export const CategoryButtonListComponent = styled(RawCategoryButtonListComponent)`
    display: flex;
     flex-flow: row wrap;
     margin: 0 -5px;
     
     @media screen and (min-width: 800px) {
     
        margin: 0 -10px;
     }
`;
