import { connect } from 'react-redux'
import {TagComponent} from "../components/TagComponent";

const mapStateToProps = (state, ownProps) => {
    var color = null;
    if (state.search.categoryId) {
        color = window.categories.find(c => c.id === state.search.categoryId).color;
    } else if (state.hintCategoryId) {
        color = window.categories.find(c => c.id === state.hintCategoryId).color;
    }
    return {
        tag: ownProps.tag,
        isSmall: true,
        background: color
    }
}

const mapDispatchToProps = dispatch => ({
    tagClicked: (tag) => {
        dispatch({type: 'ADD_TAG', tag: tag, clearAll: true})
    }
})

export const TagSuggestionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TagComponent);
