import { connect } from 'react-redux'
import {SearchInputComponent} from "../components/SearchInputComponent";

const mapStateToProps = (state, ownProps) => {

    const tags = state.search.categoryId === null ? window.tags : window.categoryTags[state.search.categoryId]
    return {
        tags: tags,
        input: state.search.input,
        inputTags: state.search.tags,
        inputCategoryId: state.search.categoryId,
        inputFocus: state.inputFocus // required for updating the visibility of the tag autosuggest
    }
}

const mapDispatchToProps = dispatch => ({
    inputChanged: input => dispatch({type: 'CHANGE_INPUT', input: input}),
    tagSelected: tag => dispatch({type: 'ADD_TAG', tag: tag, clearInput: true}),
    inputFocused: () => dispatch({type: 'INPUT_FOCUSED'}),
    inputBlurred: () => dispatch({type: 'INPUT_BLURRED'}),
    backspacePressed: () => {
        dispatch({type: 'POP_TAG'})
    }
})

export const SearchInputContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchInputComponent);