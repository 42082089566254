import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import {HeaderComponent} from "./components/HeaderComponent";
import {CategoryButtonListContainer} from "./containers/CategoryButtonListContainer";
import {SearchContainer} from "./containers/SearchContainer";
import {LoadingContainer} from "./containers/LoadingContainer";
import {SearchResultsContainer} from "./containers/SearchResultsContainer";
import Provider from "react-redux/es/components/Provider";
import {createStore} from "redux";
import stateReducer from "./reducers/StateReducer";
import {initialState} from "./data/InitialState";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import {InfoComponent} from "./components/InfoComponent";
import {InstitutionPopupComponent} from "./components/InstitutionPopupComponent";
import {TagSuggestionsContainer} from "./containers/TagSuggestionsContainer";

ReactGA.initialize('G-BH6FPL99YW');

let previousPathname = window.location.pathname;

const history = createBrowserHistory()
history.listen((location,action) => {
    if (previousPathname != location.pathname && location.pathname != "/") {
        //ReactGA.set({ page: location.pathname })
        //ReactGA.pageview(location.pathname)
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname})
    }
    previousPathname = location.pathname;
})


const store = createStore(stateReducer, initialState)

const NotFoundComponent = () => {
    return <Redirect to={"/"} />
};

const Info = (props) => {
    return <InfoComponent content={window.jungundalt.content.info} />;
}
const Emergency = (props) => {
    return <InfoComponent content={window.jungundalt.content.emergency} />;
}

class RoutedApp extends React.Component {
    componentDidMount() {
        //ReactGA.set({ page: window.location.pathname })
        //ReactGA.pageview(window.location.pathname)
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    }

    render() {
        return <Provider store={store}>
            <Router history={history}>
                <div>
                    <HeaderComponent />
                    <div>
                        <CategoryButtonListContainer />
                        <TagSuggestionsContainer />
                        <SearchContainer />
                        <LoadingContainer />
                        <SearchResultsContainer />
                    </div>
                    <Switch>
                        <Route path="/info" exact component={Info} />
                        <Route path="/notfall" exact component={Emergency} />
                        <Route path="/institution/:slug" exact component={InstitutionPopupComponent} />
                        <Route component={NotFoundComponent} />
                    </Switch>
                </div>
            </Router>
        </Provider>;
    }
}

ReactDOM.render(<RoutedApp/>, document.getElementById('root'));
