window.tags = window.jungundalt.tags;
window.institutions = new Set(window.jungundalt.institutions);
window.institutionsMap = new Map();
window.institutionsSlugMap = new Map();
var cId = 1;
var categoryColors = [
    "#DC7095",
    "#d74a43",
    "#cc903a",
    "#666995",
    "#6176dd",
    "#675069"
];
window.categories = Object.keys(window.jungundalt.categories).map((key, index) => {
    return {
        color: categoryColors[cId-1],
        id: cId++,
        name: window.jungundalt.categories[key]
    }
});
// sort them so that the "Erwachsene" comes in the middle (as requested by client)
window.categories.splice(3, 0, window.categories.pop());



window.institutions.forEach(i => {
   window.institutionsMap.set(i.id, i);
   window.institutionsSlugMap.set(i.slug, i);
});

window.categoryTags = window.jungundalt.categoryTags;


export const initialState = {
    search: {
        categoryId: null,
        tags: [],
        input: ""
    },
    requests: [],
    results: Array.from(window.institutionsMap.keys()),
    hintTagSuggestions: [],
    hintCategoryId: null,
    tagSuggestions: []
}
