import React from 'react'
import styled from 'styled-components'
import {TagSuggestionContainer} from "../containers/TagSuggestionContainer";


const RawTagSuggestionsComponent = (props) => {
    return (
        <div className={props.className}>
            {props.tags.map((t, i) => {
                return <TagSuggestionContainer key={t} tag={t} />
            })}
        </div>);
}

export const TagSuggestionsComponent = styled(RawTagSuggestionsComponent)`
    margin-top: 8px;
    margin-bottom: 6px;
    height: 69px;
    @media screen and (min-width: 362px) {
        height: 76px;
    }
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
    overflow: hidden;
    opacity: ${props => props.isHintSuggestion ? 0.55 : 0.75};
    transition: opacity 0.4s;

    margin-left: -5px;
    padding-left: 5px;
`;
