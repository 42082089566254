import React from "react";
import styled from "styled-components";
import {InstitutionComponent} from "./InstitutionComponent";
import {InfoComponent} from "./InfoComponent";
import { withRouter } from "react-router";

class RawPopupComponent extends React.PureComponent {
    componentWillUnmount() {
        window.$('body').toggleClass('popupShown', false);
    }


    constructor(props) {
        super(props);
        window.$('body').toggleClass('popupShown', true);
    }


    backgroundClick(e) {
        if (e.target == this._refElement) {
            this.props.history.push('/');
        }
    }

    closeClick() {
        this.props.history.push('/');
    }
    render() {
        return (<div ref={el => this._refElement = el} className={this.props.className} onClick={this.backgroundClick.bind(this)}>
            <div className="popup">
                <div className="closeBtn" onClick={this.closeClick.bind(this)}></div>
                { this.props.children }
            </div>
        </div>)
    }
}

export const PopupComponent = withRouter(styled(RawPopupComponent)`
  position: fixed;
  pointer-events: all;
  transition: opacity 0.3s;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.28);
  padding: 40px 15px;
  font-size: 0;
  line-height: 0;
  z-index: 10;
  
  .popup {
      margin: 0 auto;
      border: 1px solid #ccc;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      background-color: white;
      max-width: 800px;
      position: relative;
  }
  
  .content {
    margin: 30px 20px 30px 20px;
     font-size: 15px;
    line-height: 150%;
          
          h1 {
            font-size: 32px;
            line-height: 100%;
          }
          .untertitel {
            font-size: 24px;
            line-height: 100%;
          }
          p {
            margin-bottom: 0.5em;
            }
          
     @media screen and (min-width: 600px) {
        margin: 40px 40px 40px 40px;
    }
    
  }
  
  .closeBtn {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 30px;
      font-size: 70px;
      width: 50px;
      height: 50px;
      font-family: arial;
      color: rgba(0, 0, 0, 0.29);
    }
    .closeBtn:after {
        content: '×';
    }
`);