import React from 'react'
import styled from 'styled-components'
import {TagContainer} from "../containers/TagContainer";


const RawTagListComponent = (props) => {
    return (
        <div className={props.className}>
            {props.category ? <TagContainer category={props.category} isCategory={true}/> : null}
            {props.tags.map((t,i) => {
                return <TagContainer key={t} tag={t} background={props.category ? props.category.color : null}/>
            })}
        </div>);

}

export const TagListComponent = styled(RawTagListComponent)`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
    overflow: hidden;
`;
