import React from "react";
import styled from "styled-components";
import {PopupComponent} from "./PopupComponent";

class RawInfoComponent extends React.PureComponent {
    render() {
        let content = {__html: this.props.content};
        return (
            <PopupComponent>
                <div className={this.props.className}>
                    <div className="content" dangerouslySetInnerHTML={content}>
                    </div>
                </div>
            </PopupComponent>
        );
    }
}

export const InfoComponent = styled(RawInfoComponent)`

`
