import connect from "react-redux/es/connect/connect";
import {LoadingComponent} from "../components/LoadingComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        isLoading: state.waitingForResponse
    }
}

export const LoadingContainer = connect(
    mapStateToProps
)(LoadingComponent);