import React from "react";
import styled from "styled-components";
import {InfoButtonComponent} from "./InfoButtonComponent";
import {EmergencyButtonComponent} from "./EmergencyButtonComponent";

const RawHeaderComponent = (props) => {
    return <div className="HeaderComponent" className={props.className}>
            <div className="HeaderButtons">
                <InfoButtonComponent />
                <EmergencyButtonComponent />
            </div>
            <div>
                <img src="/react-files/logo.png" />
            </div>
    </div>
}

export const HeaderComponent = styled(RawHeaderComponent)`
    width: 100%;
    color: #E20319;
    margin-bottom: 12px;

    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;

    img {
        height: 20px;
        right: 0;
        top: 0;
    }

    .HeaderButtons {
        display: flex;

        a {
            padding-right: 15px;
        }
    }

    @media screen and (min-width: 362px) {
        img {
            height: 30px;
        }
    }

    @media screen and (min-width: 400px) {
        margin-bottom: 15px;
        img {
            height: 35px;
        }
    }

     @media screen and (min-width: 600px) {
        margin-bottom: 25px;
        img {
            height: 50px;
        }
    }

`;
