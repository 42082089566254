import React from 'react'
import styled, {css} from 'styled-components'

class RawTagComponent extends React.PureComponent {

    clicked() {
        this.props.tagClicked(this.props.tag, this.props.isCategory);
    }

    render() {
        return (
            <div className={`${this.props.className} ${this.props.isCategory ? 'category' : ''}`} onClick={this.clicked.bind(this)}>{this.props.tag || this.props.category.name}</div>);
    }

}

export const TagComponent = styled(RawTagComponent)`
    background: ${props => props.background ? props.background : '#9d9d9d'};
    color: white;
    display: inline-block;
    padding: ${props => props.isSmall ? "6px" : "10px"};
    border-radius: 10px;
    margin-right: 5px;
    margin-bottom: ${props => props.isSmall ? "3px" : "0"};
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    user-select: none;

    &.category {
        background: ${props => props.isCategory ? props.category.color : "white"};
    }

    font-size: ${props => props.isSmall ? "15px" : "18px"};
    @media screen and (min-width: 500px) {
        font-size: ${props => props.isSmall ? "15px" : "24px"};
    }

    transition: transform 0.2s;
    ${props => props.isSmall && css`
        &:hover {
            transform: scale(1.04);
        }

        //cursor: url('/themes/jungundalt/assets/plus.svg') 9 10, pointer;
    `}
    ${props => !props.isSmall && css`
        &:hover {
            //transform: scale(0.95);
        }
        //cursor: url('/themes/jungundalt/assets/minus.svg') 9 10, pointer;
    `}
`;
