import { connect } from 'react-redux'
import {CategoryButtonListComponent} from "../components/CategoryButtonListComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        categories: window.categories.map(c => c)
    }
}

export const CategoryButtonListContainer = connect(
    mapStateToProps
)(CategoryButtonListComponent);