import { connect } from 'react-redux'
import {SearchInputComponent} from "../components/SearchInputComponent";
import {TagListComponent} from "../components/TagListComponent";
import {TagComponent} from "../components/TagComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        tag: ownProps.tag,
        category: ownProps.category,
        isCategory: ownProps.isCategory,
        background: ownProps.background
    }
}

const mapDispatchToProps = dispatch => ({
    tagClicked: (tag, isCategory) => {
        if (isCategory) {
            dispatch({type: 'UNSET_CATEGORY', category: tag})
        } else {
            dispatch({type: 'REMOVE_TAG', tag: tag})
        }
    }
})

export const TagContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TagComponent);
