function stateReducer(state, action) {
    switch (action.type) {
        case 'TOGGLE_CATEGORY':
            const switchedOff = state.search.categoryId === action.categoryId

            var newTags = state.search.tags ? [...state.search.tags] : [];

            if (action.categoryId !== null) {
                newTags = newTags.filter(t => {
                    return window.categoryTags[action.categoryId].find(i => i === t)
                })
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    tags: newTags,
                    categoryId: switchedOff ? null : action.categoryId
                }
            }
        case 'UNSET_CATEGORY':
            return {
                ...state,
                search: {
                    ...state.search,
                    categoryId: null
                }
            }
        case 'ADD_TAG':
            return {
                ...state,
                search: {
                    ...state.search,
                    input: action.clearInput || action.clearAll ? "" : state.search.input,
                    tags: action.clearAll ? [action.tag] : [
                        // prevent duplicate tag entries
                        ...state.search.tags.filter(t => t !== action.tag),
                        action.tag
                    ]
                }
            }
        case 'REMOVE_TAG':
            return {
                ...state,
                search: {
                    ...state.search,
                    tags: state.search.tags.filter(t => t !== action.tag)
                }
            }
        case 'POP_TAG':
            if (state.search.tags.length > 0) {
                const tags = state.search.tags.slice(0);
                tags.pop();
                return {
                    ...state,
                    search: {
                        ...state.search,
                        tags: tags
                    }
                }
            } else {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        categoryId: null
                    }
                }
            }
        case 'CHANGE_INPUT':
            const newInput = action.input.replace(/^\s+/g, "");;
            return {
                ...state,
                search: {
                    ...state.search,
                    input: newInput
                }
            }

        case 'SEARCH_CHANGED':
            const search = action.search;
            if (search.tags.length <= 0 && !search.categoryId) {
                if (search.input.length < 3) {
                    return {...state};
                } else if (search.input.length <= 0) {

                }
            }
            return {
                ...state
            }
        case 'CHANGE_RESULTS':
            return {
                ...state,
                results: action.results
            }
        case 'CLEAR_RESULTS':
            return {
                ...state,
                results: Array.from(window.institutionsMap.keys())
            }
        case 'INPUT_FOCUSED':
            return {
                ...state,
                inputFocus: true
            }
        case 'INPUT_BLURRED':
            return {
                ...state,
                inputFocus: false
            }
        case 'WAITING_FOR_RESPONSE':
            return {
                ...state,
                waitingForResponse: action.waiting
            }
        case 'HINT_TAG_SUGGESTIONS':
            return {
                ...state,
                hintTagSuggestions: action.tags,
                hintCategoryId: action.categoryId
            }
        case 'SET_TAG_SUGGESTIONS':
            return {
                ...state,
                tagSuggestions: action.tags
            }
        default:
            return {
                ...state
            };

    }
}

export default stateReducer;
