import React from "react";
import styled from "styled-components";

const RawLoadingComponent = (props) => {
    return props.isLoading ? <img className={props.className} src="/react-files/bubble.svg" /> : null
}

export const LoadingComponent = styled(RawLoadingComponent)`
    height: 70px;
    position: absolute;
    margin-top: -13px;
`
